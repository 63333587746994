import React from 'react';
import PoliticaPrivacidadeComponent from '~/components/PoliticaPrivacidade';

export default function PoliticaPrivacidade() {
  return (
    <PoliticaPrivacidadeComponent
      nome="Wodo"
      email=""
      site={process.env.REACT_APP_URL}
      cnpj=""
      endereco=""
      razaoSocial="Work Done"
      plataforma=""
      titleColor="#42aaff"
      contentTextColor="#7b9db7"
      bgSecondaryColor="#fbfcfc"
    />
  );
}
