import styled from 'styled-components';
import { lighten } from 'polished';

let banner;

if (window.innerWidth >= 991) {
  banner = require('~/assets/banners/banner.svg');
} else {
  banner = require('~/assets/banners/banner-mobile.svg');
}

export const Container = styled.div``;

export const Banner = styled.div`
  background-size: cover;
  background-image: url(${banner});
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 650px;
  display: flex;
  align-items: center;

  h1 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  form {
    textarea {
      min-height: 100px;
    }

    button {
      background-color: #41637e;
      color: #fff;
      width: 100%;

      :hover {
        color: #fff;
        background-color: ${lighten(0.05, '#41637e')};
      }
    }

    span {
      margin-top: -0.5rem;
      font-size: 80%;
      color: #dc3545;
      display: block;

      + button {
        margin-top: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 991px) {
    min-height: 150px;
  }
`;
