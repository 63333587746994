import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  height: 90px;

  > div {
    background: #fafdff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

    .logo {
      color: #41637e;
      transition-duration: 0.3s;

      :hover {
        text-decoration: none;
        opacity: 0.9;
      }
    }

    .menu-btn {
      border: none;
      cursor: pointer;
      &:active,
      &:focus {
        outline: none;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 12px;
        background: #333;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        transition: all 0.2s;
        opacity: 0;
      }
      &__icon {
        position: relative;
        width: 50px;
        height: 40px;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .icon--menu {
      span {
        display: block;
        width: 50px;
        height: 4px;
        background: #333;
        transition: all 0.18s;
        transition-timing-function: $transition;
        transition-delay: 0.05s;
        &:not(last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .icon--close {
      transform: scale(0);
      transform-origin: center center;
      transition: all 0.18s;
      transition-delay: 0.08s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
      opacity: 0;

      span {
        position: relative;
        display: block;
        width: 50px;
        height: 4px;
        background: #333;
        &:nth-of-type(1) {
          top: 17px;
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          top: 13px;
          transform: rotate(-45deg);
        }
      }
    }

    .is--active {
      .icon--menu {
        span {
          width: 0;
        }
      }
      .icon--close {
        transform: scale(1);
        opacity: 1;
      }
    }

    .is--clicked {
      &:after {
        animation: btnClick 0.35s;
        animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
      }
    }

    @keyframes btnClick {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      50% {
        opacity: 0.35;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(0);
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    .slide-in-left {
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @-webkit-keyframes slide-in-left {
      0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes slide-in-left {
      0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }

    .slide-out-left {
      -webkit-animation: slide-out-left 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    }

    @-webkit-keyframes slide-out-left {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
    }

    @keyframes slide-out-left {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
    }

    img {
      max-width: none;
    }
  }

  @media screen and (max-width: 991px) {
    height: 60px;

    .align-mobile {
      align-items: center;
    }
  }
`;

export const MenuButton = styled.button`
  width: 40px;
  height: 40px;

  :focus {
    outline: none;
  }

  span {
    width: 100%;
    height: 5px;
    border-radius: 50px;
    display: block;
    background-color: #41637e;
    transition-duration: 0.3s;

    :nth-child(2) {
      margin: 5px 0;
    }
  }

  ${props =>
    props.active &&
    css`
      span {
        :nth-child(1) {
          transform: rotate(45deg);
        }

        :nth-child(2) {
          opacity: 0;
        }

        :nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -20px;
        }
      }
    `}
`;

export const Menu = styled.ul`
  li {
    display: flex;
    align-items: center;
    a,
    button {
      color: #bdbdbd;
      transition-duration: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;

      :hover {
        color: ${shade(0.5, '#bdbdbd')};
        text-decoration: none;
      }
    }

    a.active {
      color: ${shade(0.5, '#bdbdbd')};
    }
  }
  .cadastro {
    background: #41637e;
    border-radius: 0px 0px 20px 20px;
    padding: 10px;

    a {
      color: #ffffff;

      :hover {
        color: ${shade(0.1, '#ffffff')};
      }
    }
  }

  @media screen and (max-width: 991px) {
    background: #fff;
    width: 100%;
    top: 60px;
    min-height: 100vh;
    transition-duration: 0.3s;
    left: ${props => (props.active ? '0' : '-1000px')};

    li {
      a,
      button {
        justify-content: flex-start;
      }
    }

    .cadastro {
      border-radius: 20px;
    }
  }
`;
