import React from 'react';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import {
  Container,
  Banner,
  Infos,
  Qualidade,
  MaisServicos,
  Solucione,
  Servicos,
  Contato,
} from './styles';

import perfil from '~/assets/icons/perfil-icon.svg';
import servicos from '~/assets/icons/servicos-icon.svg';
import chat from '~/assets/icons/chat-icon.svg';
import qualidade from '~/assets/defaults/bg-qualidade-mobile.svg';
import maisServicos from '~/assets/defaults/mais-servicos-vetor.svg';
import solucione from '~/assets/defaults/solucione-vetor.svg';
import servicosVet from '~/assets/defaults/servicos-vetor.svg';
import bgServicos from '~/assets/defaults/bg-servicos.svg';

let contato;
if (window.innerWidth >= 991) {
  contato = require('~/assets/defaults/contato-vetor.svg');
} else {
  contato = require('~/assets/defaults/contato-vetor-mobile.svg');
}

function Home() {
  return (
    <>
      <Container>
        <Banner>
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="py-3 col-lg-5 text-center">
                <h1 className="h3 display-lg-4 font-weight-bold mb-1 mb-lg-3">
                  Work Done
                </h1>
                <p className="h5-lg">
                  Descubra a plataforma que lhe dá a oportunidade de oferecer
                  seus serviços ao público de forma simples e rápida.
                </p>
              </div>
            </div>
          </div>
        </Banner>

        <Infos className="container py-5" id="content">
          <div className="row">
            <div className="col-lg-4 mt-5 text-center">
              <img src={perfil} alt="Perfil" className="w-50 mb-3" />
              <h2 className="h4 h1-lg font-weight-bold">Perfil</h2>
              <p className="h5-lg font-weight-normal">
                Aqui você pode criar um perfil, divulgando seus serviços e
                mostrando para outras pessoas como seu trabalho funciona.
              </p>
            </div>
            <div className="col-lg-4 mt-5 text-center">
              <img src={servicos} alt="Servicos" className="w-50 mb-3" />
              <h2 className="h4 h1-lg font-weight-bold">Serviços</h2>
              <p className="h5-lg font-weight-normal">
                Em serviços, você encontra diferentes tipos trabalhos podendo
                oferecer ou até mesmo&nbsp;
                <br className="d-block d-sm-none" />
                encontrar um.
              </p>
            </div>
            <div className="col-lg-4 mt-5 text-center">
              <img src={chat} alt="Chat" className="w-50 mb-3" />
              <h2 className="h4 h1-lg font-weight-bold">Mensagens</h2>
              <p className="h5-lg font-weight-normal">
                Conecte-se com outros usuários, converse, planeje tudo. Você
                também pode gerar porpostas e solicitar orçamentos no chat.
              </p>
            </div>
          </div>
        </Infos>

        <Qualidade className="my-lg-5">
          <div className="container py-lg-5">
            <div className="row align-itens-center py-lg-5">
              <div className="col-12 p-0 d-block d-lg-none">
                <img src={qualidade} alt="Qualidade" className="w-100" />
              </div>
              <div className="col-lg-4 py-5 mt-lg-5">
                <h3 className="h4 h1-lg font-weight-bold">Qualidade</h3>
                <p className="h5-lg font-weight-normal my-2 my-lg-4">
                  Todos os serviços que foram pestados serão avaliados, ou seja,
                  quando surgir dúvida, você pode checar as avaliações.
                </p>
                <a
                  href={`${process.env.REACT_APP_WEB_URL}/cadastrar`}
                  className="h5-lg font-weight-normal"
                >
                  Get started <BsArrowRight size={25} />
                </a>
              </div>
            </div>
          </div>
        </Qualidade>

        <MaisServicos className="py-lg-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <img src={maisServicos} alt="Mais Serviços" className="w-100" />
              </div>
              <div className="col-lg-6 text-right">
                <h3 className="h4 h1-lg font-weight-bold">Mais Serviço</h3>
                <p className="h5-lg font-weight-normal my-2 my-lg-4">
                  Com a Wodo, você pode divulgar o seu serviço fazendo com que
                  mais pessoas possam ver o seu trabalho, e tendo mais clientes
                  para o seu ramo fornecido.
                </p>
                <a
                  href={`${process.env.REACT_APP_WEB_URL}/cadastrar`}
                  className="h5-lg font-weight-normal"
                >
                  <BsArrowLeft size={25} /> Get started
                </a>
              </div>
            </div>
          </div>
        </MaisServicos>

        <Solucione className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-0">
                <h3 className="h4 h1-lg font-weight-bold">Solucione Já!</h3>
                <p className="h5-lg font-weight-normal my-2 my-lg-4">
                  Encontre ou Forneça um serviço, você pode também comunicar-se
                  com os usuários.
                </p>
                <a
                  href={`${process.env.REACT_APP_WEB_URL}/cadastrar`}
                  className="h5-lg font-weight-normal"
                >
                  Get started <BsArrowRight size={25} />
                </a>
              </div>
              <div className="col-lg-6 order-0 order-lg-1 mb-4 mb-lg-0">
                <img src={solucione} alt="Solucione" className="w-100" />
              </div>
            </div>
          </div>
        </Solucione>

        <Servicos className="my-lg-5">
          <div className="container py-lg-5">
            <div className="row justify-content-end">
              <div className="col-lg-7">
                <div className="row justify-content-end">
                  <div className="col-12">
                    <img src={servicosVet} alt="Serviços" className="w-100" />
                  </div>
                  <div className="col-lg-10 text-right">
                    <p className="h5-lg font-weight-normal mt-4 mb-2 mt-lg-5 mb-lg-4">
                      Encontre o serviço que deseja agora mesmo ou forneça um,
                      inicie agora mesmo com a WODO.
                    </p>
                    <a
                      href={`${process.env.REACT_APP_WEB_URL}/cadastrar`}
                      className="h5-lg font-weight-normal"
                    >
                      <BsArrowLeft size={25} /> Get started
                    </a>
                  </div>
                  <div className="col-12 d-block d-lg-none">
                    <img
                      src={bgServicos}
                      alt="Background Serviços"
                      className="w-100 mt-n5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Servicos>

        <Contato id="contato" className="py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 box p-5">
                <div className="row pb-5 mb-5">
                  <div className="col-lg-8">
                    <h3 className="h4 h1-lg font-weight-bold">
                      Contate a Wodo
                    </h3>
                    <p className="h5-lg font-weight-normal my-2 my-lg-4">
                      Algum problema? <br />
                      Não se preocupe, estamos aqui e vamos te ouvir,
                      contate-nos agora mesmo.
                    </p>
                    <Link to="contato" className="h5-lg font-weight-normal">
                      Enviar <BsArrowRight size={25} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-n5 mt-lg-0 contato-mobile">
                <img src={contato} alt="Contato" className="w-100" />
              </div>
            </div>
          </div>
        </Contato>
      </Container>
    </>
  );
}

export default Home;
