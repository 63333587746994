/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '~/pages/Home';
import Contato from '~/pages/Contato';
import PoliticaPrivacidade from '~/pages/PoliticaPrivacidade';

// import Page404 from '~/pages/Page404/';

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route path={`${process.env.PUBLIC_URL}/contato`} component={Contato} />
      <Route path={`${process.env.PUBLIC_URL}/politicas-de-privacidade`} component={PoliticaPrivacidade} />

      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Page404} /> */}
    </Switch>
  );
}
