import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';

export default function PoliticaPrivacidade({
  bgPrimaryColor,
  bgSecondaryColor,
  titleColor,
  contentTextColor,
  fontRootSize,
  nome,
  email,
  site,
  cnpj,
  endereco,
  razaoSocial,
  plataforma,
}) {
  return (
    <Container bgColor={bgPrimaryColor} fontSize={fontRootSize}>
      <Content
        bgColor={bgSecondaryColor}
        titleColor={titleColor}
        textColor={contentTextColor}
      >
        <h1>Política de Privacidade {nome}</h1>
        <div>
          <div>
            <p>
              <b>SEÇÃO 1 - INFORMAÇÕES GERAIS</b>
            </p>
            <p>
              A presente Política de Privacidade contém informações sobre
              coleta, uso, armazenomento, tratamento e proteção dos dados
              pessoais dos usuários e visitantes do site {nome}, com a
              finalidade de demonstrar absoluta transparência quanto ao assunto
              e esclarecer a todos interessados sobre os tipos de dados que são
              coletados, os motivos da coleta e a forma como os usuários podem
              gerenciar ou excluir as suas informações pessoais;
            </p>
            <p>
              Esta Política de Privacidade aplica-se a todos os usuários e
              visitantes do site {nome} e integra os Termos e Condições Gerais
              de Uso do site <a href={site}>{site}</a> devidamente inscrita no
              CNPJ sob o nº {cnpj}, situado em {endereco}, doravante nominada{' '}
              {razaoSocial}.
            </p>
            <p>
              O presente documento foi elaborado em conformidade com a Lei Geral
              de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da
              Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790).
              Ainda, o documento poderá ser atualizado em decorrência de
              eventual atualização normativa, razão pela qual se convida o
              usuário a consultar periodicamente esta seção.
            </p>
          </div>

          <div>
            <p>
              <b>
                SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
                VISITANTE?
              </b>
            </p>
            <p>
              Os dados pessoais do usuário e visitante são recolhidos pela
              plataforma da seguinte forma:
            </p>
            <ul>
              <li>
                <p>
                  Quando um usuário e visitante acessa páginas do as informações
                  sobre interação e acesso são coletadas pela empresa para
                  garantir uma melhor experiência ao usuário e visitante. Estes
                  dados podem tratar sobre as palavras-chaves utilizadas em uma
                  busca, o compartilhamento de um documento específico,
                  comentários, visualizações de páginas, perfis, a URL de onde o
                  usuário e visitante provêm, o navegador que utilizam e seus
                  IPs de acesso, dentre outras que poderão ser armazenadas e
                  retidas.
                </p>
              </li>
              <li>
                <p>
                  Por intermédio de terceiro: a plataforma {nome} recebe dados
                  de terceiros, como {plataforma}, quando um usuário faz login
                  com o seu perfil de um desses sites. A utilização desses dados
                  é autorizada previamente pelos usuários junto ao terceiro em
                  questão.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <p>
              <b>
                SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E
                VISITANTE?
              </b>
            </p>
            <p>
              Os dados pessoais do usuário e visitante recolhidos são os
              seguintes:
            </p>
            <ul>
              <li>
                <p>
                  Dados para otimização da navegação: acesso a páginas, tempo de
                  navegação, palavras-chave utilizadas na busca.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <p>
              <b>
                SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO
                USUÁRIO E VISITANTE?
              </b>
            </p>
            <p>
              Os dados pessoais do usuário e do visitante coletados e
              armazenados pela plataforma {nome} tem por finalidade:
            </p>
            <ul>
              <li>
                <p>
                  Bem-estar do usuário e visitante: aprimorar o produto e/ou
                  serviço oferecido, facilitar, agilizar e cumprir os
                  compromissos estabelecidos entre o usuário e a empresa,
                  melhorar a experiência dos usuários e fornecer funcionalidades
                  específicas a depender das características básicas do usuário.
                </p>
              </li>
              <li>
                <p>
                  Melhorias da plataforma: compreender como o usuário utiliza os
                  serviços da plataforma, para ajudar no desenvolvimento de
                  negócios e técnicas.
                </p>
              </li>
              <li>
                <p>
                  Anúncios: apresentar anúncios personalizados para o usuário
                  com base nos dados fornecidos.
                </p>
              </li>
              <li>
                <p>
                  Comercial: os dados são usados para personalizar o conteúdo
                  oferecido e gerar subsídio à plataforma para a melhora da
                  qualidade no funcionomento dos serviços.
                </p>
              </li>
              <li>
                <p>
                  Previsão do perfil do usuário: tratamento automatizados de
                  dados pessoais para avaliar o uso na plataforma.
                </p>
              </li>
            </ul>
            <p>
              O tratamento de dados pessoais para finalidades não previstas
              nesta Política de Privacidade somente ocorrerá mediante
              comunicação prévia ao usuário, de modo que os direitos e
              obrigações aqui previstos permanecem aplicáveis.
            </p>
          </div>

          <div>
            <p>
              <b>
                SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
              </b>
            </p>
            <p>
              Os dados pessoais do usuário e visitante são armazenados pela
              plataforma durante o período necessário para a prestação do
              serviço ou o cumprimento das finalidades previstas no presente
              documento, conforme o disposto no inciso I do artigo 15 da Lei
              13.709/18.
            </p>
            <p>
              Os dados podem ser removidos ou anonimizados a pedido do usuário,
              excetuando os casos em que a lei oferecer outro tratamento.
            </p>
            <p>
              Ainda, os dados pessoais dos usuários apenas podem ser conservados
              após o término de seu tratamento nas seguintes hipóteses previstas
              no artigo 16 da referida lei:
            </p>
            <ol>
              <li>
                <p>
                  I - cumprimento de obrigação legal ou regulatória pelo
                  controlador;
                </p>
              </li>
              <li>
                <p>
                  II - estudo por órgão de pesquisa, garantida, sempre que
                  possível, a anonimização dos dados pessoais;
                </p>
              </li>
              <li>
                <p>
                  III - transferência a terceiro, desde que respeitados os
                  requisitos de tratamento de dados dispostos nesta Lei;
                </p>
              </li>
              <li>
                <p>
                  IV - uso exclusivo do controlador, vedado seu acesso por
                  terceiro, e desde que anonimizados os dados;
                </p>
              </li>
            </ol>
          </div>

          <div>
            <p>
              <b>SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</b>
            </p>
            <p>
              A plataforma se compromete a aplicar as medidas técnicas e
              organizativas aptas a proteger os dados pessoais de acessos não
              autorizados e de situações de destruição, perda, alteração,
              comunicação ou difusão de tais dados.
            </p>
            <p>
              A plataforma não se exime de responsabilidade por culpa exclusiva
              de terceiro, como em caso de ataque de hackers ou crackers, ou
              culpa exclusiva do usuário, como no caso em que ele mesmo
              transfere seus dados a terceiros. O site se compromete a comunicar
              o usuário em caso de alguma violação de segurança dos seus dados
              pessoais.
            </p>
            <p>
              Os dados pessoais armazenados são tratados com confidencialidade,
              dentro dos limites legais. No entanto, podemos divulgar suas
              informações pessoais caso sejamos obrigados pela lei para fazê-lo
              ou se você violar nossos Termos de Serviço.
            </p>
          </div>

          <div>
            <p>
              <b>SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO</b>
            </p>
            <p>
              Os cookies referem-se a arquivos de texto enviados pela plataforma
              ao computador do usuário e visitante e que nele ficam armazenados,
              com informações relacionadas à navegação no site. Tais informações
              são relacionadas aos dados de acesso como local e horário de
              acesso e são armazenadas pelo navegador do usuário e visitante
              para que o servidor da plataforma possa lê-las posteriormente a
              fim de personalizar os serviços da plataforma.
            </p>
            <p>
              O usuário e o visitante da plataforma {nome} manifesta conhecer e
              aceitar que pode ser utilizado um sistema de coleta de dados de
              navegação mediante à utilização de cookies.
            </p>
            <p>
              O cookie persistente permanece no disco rígido do usuário e
              visitante depois que o navegador é fechado e será usado pelo
              navegador em visitas subsequentes ao site. Os cookies persistentes
              podem ser removidos seguindo as instruções do seu navegador. Já o
              cookie de sessão é temporário e desaparece depois que o navegador
              é fechado. É possível redefinir seu navegador da web para recusar
              todos os cookies, porém alguns recursos da plataforma podem não
              funcionar corretamente se a capacidade de aceitar cookies estiver
              desabilitada.
            </p>
          </div>

          <div>
            <p>
              <b>SEÇÃO 8 - CONSENTIMENTO</b>
            </p>
            <p>
              Ao utilizar os serviços e fornecer as informações pessoais na
              plataforma, o usuário está consentindo com a presente Política de
              Privacidade.
            </p>
            <p>
              O usuário tem direito de retirar o seu consentimento a qualquer
              tempo, para tanto deve entrar em contato através do e-mail
              <a href={`mailto:${email}`}>{email}</a>
            </p>
          </div>

          <div>
            <p>
              <b>SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</b>
            </p>
            <p>
              Reservamos o direito de modificar essa Política de Privacidade a
              qualquer momento, então, é recomendável que o usuário e visitante
              revise-a com frequência.
            </p>
            <p>
              As alterações e esclarecimentos vão surtir efeito imediatamente
              após sua publicação na plataforma. Quando realizadas alterações os
              usuários serão notificados. Ao utilizar o serviço ou fornecer
              informações pessoais após eventuais modificações, o usuário e
              visitante demonstra sua concordância com as novas normas.
            </p>
            <p>
              Diante da fusão ou venda da plataforma à outra empresa os dados
              dos usuários podem ser transferidas para os novos proprietários
              para que a permanência dos serviços oferecidos.
            </p>
          </div>

          <div>
            <p>
              <b>SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</b>
            </p>
            <p>
              Para a solução de controvérsias decorrentes do presente
              instrumento será aplicado integralmente o Direito brasileiro.
            </p>
            <p>
              Os eventuais litígios deverão ser apresentados no foro da comarca
              em que se encontra a sede da empresa.
            </p>
          </div>
        </div>
      </Content>
    </Container>
  );
}

PoliticaPrivacidade.propTypes = {
  bgPrimaryColor: PropTypes.string,
  bgSecondaryColor: PropTypes.string,
  titleColor: PropTypes.string,
  contentTextColor: PropTypes.string,
  fontRootSize: PropTypes.string,
  nome: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  endereco: PropTypes.string.isRequired,
  razaoSocial: PropTypes.string.isRequired,
  plataforma: PropTypes.string.isRequired,
};

PoliticaPrivacidade.defaultProps = {
  bgPrimaryColor: '#fff',
  bgSecondaryColor: '#00000008',
  titleColor: '#303030',
  contentTextColor: '#303030',
  fontRootSize: '56%',
};
