import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Container, MenuButton, Menu } from './styles';

import logo from '~/assets/logotipos/logo.svg';

function Header() {
  const location = useLocation();
  const [openedMobile, setOpenedMobile] = useState(false);

  useEffect(() => {
    setOpenedMobile(false);
  }, [location]);

  return (
    <Container>
      <div className="fixed-top py-2 pt-lg-0">
        <div className="container px-0 px-lg-3">
          <nav className="navbar navbar-toggleable-sm navbar-expand-lg py-0 px-0 px-lg-3">
            <div className="col-12 d-flex justify-content-around justify-content-lg-between px-0 px-lg-3 align-mobile">
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="d-flex align-items-end logo pt-2"
              >
                <img src={logo} className="w-25 w-md-50" alt="Logo" />
                <span className="h4 mb-0 mb-lg-2 ml-1 ml-lg-3 font-weight-normal">
                  WODO
                </span>
              </Link>
              <MenuButton
                type="button"
                className="d-block d-lg-none"
                active={openedMobile}
                onClick={() => setOpenedMobile(!openedMobile)}
              >
                <span />
                <span />
                <span />
              </MenuButton>
              <Menu
                active={openedMobile}
                className="nav navbar-nav p-absolute p-lg-relative"
              >
                <li className="p-3 px-lg-0 nav-item mr-lg-4">
                  <Link
                    to="/"
                    className={location.pathname === '/' ? 'active' : ''}
                  >
                    Home
                  </Link>
                </li>
                <li className="p-3 px-lg-0 nav-item mx-lg-4">
                  <Link
                    to="/contato"
                    className={location.pathname === '/contato' ? 'active' : ''}
                  >
                    Contato
                  </Link>
                </li>
                <li className="p-3 px-lg-0 nav-item mx-lg-4">
                  <a href={`${process.env.REACT_APP_WEB_URL}/login`}>Logar</a>
                </li>
                <li className="p-3 px-lg-4 nav-item mx-lg-4 cadastro">
                  <a href={`${process.env.REACT_APP_WEB_URL}/cadastrar`}>
                    Cadastrar-me
                  </a>
                </li>
              </Menu>
            </div>
          </nav>
        </div>
      </div>
    </Container>
  );
}

export default Header;
