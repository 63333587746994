import styled from 'styled-components';
import { shade } from 'polished';

import bgQualidade from '~/assets/defaults/bg-qualidade.svg';
import bgServicos from '~/assets/defaults/bg-servicos.svg';

let banner;

if (window.innerWidth >= 991) {
  banner = require('~/assets/banners/banner.svg');
} else {
  banner = require('~/assets/banners/banner-mobile.svg');
}

export const Container = styled.div``;

export const Banner = styled.div`
  background-size: cover;
  background-image: url(${banner});
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 650px;
  display: flex;
  align-items: center;

  h1 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  @media screen and (max-width: 991px) {
    min-height: 150px;
  }
`;

export const Infos = styled.div`
  h2 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }
`;

export const Qualidade = styled.div`
  background-size: cover;
  background-image: url(${bgQualidade});
  background-position: top center;
  background-repeat: no-repeat;

  h3 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  a {
    color: #ffca18;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      color: ${shade(0.1, '#FFCA18')};
    }
  }

  @media screen and (max-width: 991px) {
    background: none;
  }
`;

export const MaisServicos = styled.div`
  h3 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  a {
    color: #ffca18;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      color: ${shade(0.1, '#FFCA18')};
    }
  }
`;

export const Solucione = styled.div`
  h3 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  a {
    color: #ffca18;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      color: ${shade(0.1, '#FFCA18')};
    }
  }
`;

export const Servicos = styled.div`
  background-size: contain;
  background-image: url(${bgServicos});
  background-position: bottom left;
  background-repeat: no-repeat;

  h3 {
    color: #42aaff;
  }

  p {
    color: #7b9db7;
  }

  a {
    color: #ffca18;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      color: ${shade(0.1, '#FFCA18')};
    }
  }

  @media screen and (max-width: 991px) {
    background: none;
  }
`;

export const Contato = styled.div`
  .box {
    background: #fbfcfc;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
      -1px -1px 2px rgba(204, 205, 207, 0.5),
      inset -5px 5px 10px rgba(204, 205, 207, 0.2),
      inset 5px -5px 10px rgba(204, 205, 207, 0.2),
      inset -5px -5px 10px rgba(255, 255, 255, 0.9),
      inset 5px 5px 13px rgba(204, 205, 207, 0.9);
    border-radius: 48px 24px;

    h3 {
      color: #42aaff;
    }

    p {
      color: #7b9db7;
    }

    a {
      color: #ffca18;
      transition-duration: 0.3s;

      :hover {
        text-decoration: none;
        color: ${shade(0.1, '#FFCA18')};
      }
    }
  }

  @media screen and (max-width: 991px) {
    .contato-mobile {
      position: relative;
      z-index: -1;
    }
  }
`;
