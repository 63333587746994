import React from 'react';

import { Link } from 'react-router-dom';
import { Container } from './styles';

import logo from '~/assets/logotipos/logo.svg';

function Footer() {
  return (
    <>
      <Container>
        <div className="bg-footer py-3 mt-4">
          <div className="container">
            <div className="row justify-content-lg-between align-items-center">
              <div className="col-lg-3 mb-3 mb-lg-0">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="d-flex justify-content-center align-items-end logo pt-2"
                >
                  <img src={logo} className="w-25" alt="Logo" />
                  <span className="h4 ml-lg-3 font-weight-normal">WODO</span>
                </Link>
              </div>
              <div className="col-lg-5">
                <div className="row justify-content-end">
                  <div className="col-lg-6 my-2 my-lg-0 text-center">
                    <Link to="/politicas-de-privacidade">
                      Políticas de privacidade
                    </Link>
                  </div>
                  <div className="col-lg-3 my-2 my-lg-0 text-center">
                    <a href={`${process.env.REACT_APP_WEB_URL}/login`}>Logar</a>
                  </div>
                  <div className="col-lg-3 my-2 my-lg-0 text-center">
                    <Link to="/contato">Contato</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Footer;
