import React, { useMemo, useCallback } from 'react';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Container, Banner } from './styles';

function Contato() {
  const options = useMemo(
    () => [
      { id: 'Dúvida', title: 'Dúvidas' },
      { id: 'Reclamação', title: 'Reclamações' },
      { id: 'Sugestão', title: 'Sugestões' },
      { id: 'Outros', title: 'Outros' },
    ],
    []
  );

  const schema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('O nome é obrigatório.'),
      email: Yup.string()
        .email('E-mail inválido.')
        .required('O email é obrigatório.'),
      subject: Yup.string().required('O assunto é obrigatório'),
      message: Yup.string().required('A mensagem é obrigatório'),
    });
  }, []);

  const handleSubmit = useCallback(
    async ({ name, email, subject, message }, { resetForm }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/contact`,
          {
            name,
            email,
            subject,
            message,
          }
        );

        if (response.data) {
          Swal.fire(
            'Bom trabalho!',
            'Em breve entraremos em contato.',
            'success'
          );
          resetForm();
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro, tente novamente.', 'error');
        }
      } catch (error) {
        Swal.fire('Oops...', 'Ocorreu um erro, tente novamente.', 'error');
      }
    },
    []
  );

  return (
    <>
      <Container>
        <Banner>
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <h1 className="h3 display-lg-4 font-weight-bold pt-4 pt-lg-0 mb-3 text-center">
                  Fale conosco
                </h1>
                <Form onSubmit={handleSubmit} schema={schema}>
                  <Input
                    name="name"
                    placeholder="Nome"
                    className="form-control my-2"
                  />
                  <Input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    className="form-control my-2"
                  />
                  <Select
                    options={options}
                    name="subject"
                    placeholder="Selecionar"
                    className="form-control my-2"
                  />
                  <Input
                    name="message"
                    placeholder="Mensagem"
                    multiline
                    className="form-control my-2"
                  />
                  <button type="submit" className="btn">
                    Enviar
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </Banner>
      </Container>
    </>
  );
}

export default Contato;
