import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  .bg-footer {
    background-color: #d6dfe5;
  }

  a {
    color: #41637e;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      color: ${lighten(0.1, '#41637e')};
    }
  }
`;
